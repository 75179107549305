var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "el-autocomplete",
        {
          staticClass: "inline-input",
          style: { width: _vm.auwidth + "px" },
          attrs: {
            "popper-class": _vm.popperClass,
            "popper-append-to-body": "",
            "fetch-suggestions": _vm.querySearch,
            "value-key": "goods_name",
            placeholder: "请输入商品名称、简拼",
            disabled: _vm.isB,
            "trigger-on-focus": false,
            debounce: 1000,
          },
          on: { select: _vm.handleSelect, input: _vm.handleInput },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c(
                    "div",
                    {
                      staticClass: "wrap",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c("div", { staticClass: "name" }, [
                        _vm._v(" " + _vm._s(item.goods_name) + " "),
                      ]),
                      _c("span", { staticClass: "addr" }, [
                        _vm._v(_vm._s(item.specs)),
                      ]),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.goods_keyword,
            callback: function ($$v) {
              _vm.goods_keyword = $$v
            },
            expression: "goods_keyword",
          },
        },
        [
          _vm.search
            ? _c("i", {
                staticClass: "el-input__icon el-icon-search",
                staticStyle: { "padding-right": "-10px" },
                attrs: { slot: "suffix" },
                on: { click: _vm.iconClick },
                slot: "suffix",
              })
            : _vm._e(),
          _vm.isTable && _vm.rowf.child && _vm.rowf.child.length > 0
            ? _c(
                "el-dropdown",
                {
                  attrs: { slot: "suffix", trigger: "click" },
                  on: { command: _vm.handleKouweiChange },
                  slot: "suffix",
                },
                [
                  _c("span", { staticClass: "el-dropdown-link" }, [
                    _c("i", {
                      staticClass: "el-input__icon el-icon-caret-right asd",
                    }),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    _vm._l(_vm.rowf.child, function (item, index) {
                      return _c(
                        "el-dropdown-item",
                        { key: index, attrs: { command: item } },
                        [_vm._v(" " + _vm._s(item.attr) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("goods-search-dialog", {
        ref: "goodsSearchDialog",
        attrs: {
          "brand-ids": _vm.brandId,
          "is-table": _vm.isTable,
          "is-old": _vm.isOld,
          "new-obj": _vm.goodsListObj,
          dbj: _vm.dbj,
          "onlyshow-kucun": _vm.onlyshowKucun,
        },
        on: { "add-rows": _vm.addRows, "change-goods": _vm.setInput },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }