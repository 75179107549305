<template>
  <el-dialog
    :modal="false"
    :close-on-click-modal="false"
    title="选择商品"
    top="5vh"
    :visible.sync="showDialog"
    append-to-body
    width="1050px"
    @close="close"
  >
    <el-input
      v-model="keyword"
      placeholder="输入关键字进行查询"
      style="width: 250px; margin: 5px 10px 5px 0"
    ></el-input>
    <el-button style="margin-right: 10px" @click="handleQuery">查询</el-button>
    <el-checkbox
      v-if="onlyshowKucun"
      v-model="checked"
      true-label="1"
      false-label="0"
      @change="checkBoxChange"
    >
      只显示有库存商品
    </el-checkbox>
    <el-row :gutter="10">
      <el-col :span="6">
        <div class="tit" style="text-aligin: center; line-height: 30px">
          品牌
        </div>
        <div
          class="grid-content bg-purple"
          style="height: 500px; overflow: auto"
        >
          <!-- tree -->
          <el-tree
            ref="tree"
            v-loading="lloading"
            class="filter-tree"
            :data="ldata"
            :props="defaultProps"
            default-expand-all
            @node-click="treeClick"
          ></el-tree>
        </div>
      </el-col>
      <el-col :span="18">
        <div class="grid-content bg-purple">
          <!-- 右侧表格 -->
          <el-table
            ref="rtable"
            v-loading="rloading"
            stripe
            :data="list"
            :row-key="getRowKey"
            height="520px"
            @selection-change="setSelectRows"
            @cell-click="cellClick"
          >
            <!-- 选择框 -->
            <el-table-column
              v-if="isTable"
              align="center"
              type="selection"
              width="60"
              :reserve-selection="true"
            ></el-table-column>
            <!-- 序号 -->
            <el-table-column
              prop=""
              align="center"
              label="序号"
              min-width="50px"
            >
              <template #default="{ $index }">
                <span>{{ $index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="goods_name"
              align="center"
              label="商品名称"
              width=""
            ></el-table-column>
            <el-table-column
              prop="specs"
              align="center"
              label="规格"
              width=""
            ></el-table-column>
            <el-table-column
              prop="goods_code"
              align="center"
              label="小单位条码"
              width=""
            ></el-table-column>
            <el-table-column
              prop="class_name"
              align="center"
              label="品类"
              width=""
            ></el-table-column>
            <el-table-column
              prop="brand_name"
              align="center"
              label="品牌"
              width=""
            ></el-table-column>
            <el-table-column
              v-if="showKeyongkucun"
              prop="depot_stock_num_read"
              align="center"
              label="可用库存"
              width=""
            ></el-table-column>
            <el-table-column
              prop="unit_cv"
              align="center"
              label="单位换算"
              width=""
            ></el-table-column>
            <el-table-column
              prop="is_close_text"
              align="center"
              label="状态"
              width=""
            ></el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- 底部按钮 -->
    <div slot="footer" class="dialog-footer">
      <el-badge v-show="isTable" :value="tipNum" class="item">
        <el-button type="primary" @click="save">确认</el-button>
      </el-badge>
      <el-button style="margin-left: 10px" @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { brandList } from '@/api/selectList'
  import { getInputList } from '@/api/goodsSearchInput'
  export default {
    name: 'GoodsChooseDialog',
    components: {},
    props: {
      // 是否显示可用库存
      showKeyongkucun: {
        type: Boolean,
        default: () => true,
      },
      // 只显示库存
      onlyshowKucun: {
        type: Boolean,
        default: () => true,
      },
      // 付费陈列 old_sell_price
      isOld: {
        type: Boolean,
        default: () => false,
      },
      brandIds: {
        type: String,
        default: () => '',
      },
      isTable: {
        type: Boolean,
        default: () => true,
      },
      // 是否是调拨价
      dbj: {
        type: Boolean,
        default: () => false,
      },
      // 商品下拉添加参数
      newObj: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        depotId: 0,
        brandId: 0,
        custId: null,
        // 已选择的row
        selectedRow: [],
        showDialog: false,
        keyword: '',
        checked: '',
        tableLoading: false,
        ldata: [],
        list: [],
        lloading: true,
        rloading: false,
        defaultProps: {
          children: 'children',
          label: 'brand_name',
        },
      }
    },
    computed: {
      tipNum() {
        return this.selectedRow.length
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          // 获取左边 品牌数据树
          this.getBrandData()
        } else {
          this.custId = null
          this.list = []
          this.$refs.rtable.clearSelection()
          this.keyword = ''
          this.checked = '0'
          this.selectedRow = []
          this.brandId = 0
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async getBrandData() {
        this.lloading = true
        let { data, code, msg } = await brandList({
          brand_id: this.brandIds,
        })
        console.log(data)
        this.ldata = data
        this.lloading = false
      },
      close() {
        this.showDialog = false
        // this.$refs['addRowFrom'].resetFields() // 重置表单
      },
      treeClick(val) {
        console.log(val)
        this.brandId = val.id
        this.getGoodsList(val.id)
      },
      save() {
        console.log(this.selectedRow)
        if (this.selectedRow.length !== 0) {
          this.selectedRow.forEach((item) => {
            this.$set(item, 'edit', true)
            if (item.unit_id == 0) {
              item.unit_id = ''
            }
            if (item.goods_type == 0) {
              item.goods_type = 1
            }
          })
          this.$emit('add-rows', this.selectedRow)
          this.close()
        } else {
          this.$message.error('请选择商品')
        }
      },
      async handleQuery() {
        // this.$refs.tree.filter(this.keyword)
        console.log('查询')
        if (this.keyword == '') {
          this.$message.error('请输入关键字')
        } else {
          this.rloading = true
          let { data, msg, code, totalCount } = await getInputList({
            ...{
              pageNo: 1,
              pageSize: -1,
              depot_id: this.depotId,
              has_stock: this.checked,
              keyword: this.keyword,
              custom_id: this.custId,
              // brand_id: this.brandId,
            },
            ...this.newObj,
          })
          if (code == 200) {
            console.log(this.list)
            this.list = data
          }
          this.rloading = false
        }
      },
      setSelectRows(val) {
        // console.log(val)
        this.selectedRow = val
      },
      async getGoodsList(brandId) {
        this.rloading = true
        let { data, msg, code, totalCount } = await getInputList({
          ...{
            pageNo: 1,
            pageSize: -1,
            depot_id: this.depotId,
            brand_id: brandId,
            has_stock: this.checked,
            custom_id: this.custId,
          },
          ...this.newObj,
        })
        if (code == 200) {
          console.log(this.list)
          this.list = data
        }
        this.rloading = false
      },
      getRowKey(row) {
        return row.id
      },
      cellClick(row) {
        if (!this.isTable) {
          console.log(row)
          this.$emit('change-goods', row)
          this.showDialog = false
        }
      },
      checkBoxChange(val) {
        console.log(val)
        console.log(this.brandId)
        if (this.brandId != 0) {
          this.getGoodsList(this.brandId)
        }
      },
    },
  }
</script>
<style lang="scss" scoped></style>
