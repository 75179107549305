var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        "close-on-click-modal": false,
        title: "选择商品",
        top: "5vh",
        visible: _vm.showDialog,
        "append-to-body": "",
        width: "1050px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
        close: _vm.close,
      },
    },
    [
      _c("el-input", {
        staticStyle: { width: "250px", margin: "5px 10px 5px 0" },
        attrs: { placeholder: "输入关键字进行查询" },
        model: {
          value: _vm.keyword,
          callback: function ($$v) {
            _vm.keyword = $$v
          },
          expression: "keyword",
        },
      }),
      _c(
        "el-button",
        {
          staticStyle: { "margin-right": "10px" },
          on: { click: _vm.handleQuery },
        },
        [_vm._v("查询")]
      ),
      _vm.onlyshowKucun
        ? _c(
            "el-checkbox",
            {
              attrs: { "true-label": "1", "false-label": "0" },
              on: { change: _vm.checkBoxChange },
              model: {
                value: _vm.checked,
                callback: function ($$v) {
                  _vm.checked = $$v
                },
                expression: "checked",
              },
            },
            [_vm._v(" 只显示有库存商品 ")]
          )
        : _vm._e(),
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c("el-col", { attrs: { span: 6 } }, [
            _c(
              "div",
              {
                staticClass: "tit",
                staticStyle: { "text-aligin": "center", "line-height": "30px" },
              },
              [_vm._v(" 品牌 ")]
            ),
            _c(
              "div",
              {
                staticClass: "grid-content bg-purple",
                staticStyle: { height: "500px", overflow: "auto" },
              },
              [
                _c("el-tree", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.lloading,
                      expression: "lloading",
                    },
                  ],
                  ref: "tree",
                  staticClass: "filter-tree",
                  attrs: {
                    data: _vm.ldata,
                    props: _vm.defaultProps,
                    "default-expand-all": "",
                  },
                  on: { "node-click": _vm.treeClick },
                }),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: 18 } }, [
            _c(
              "div",
              { staticClass: "grid-content bg-purple" },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.rloading,
                        expression: "rloading",
                      },
                    ],
                    ref: "rtable",
                    attrs: {
                      stripe: "",
                      data: _vm.list,
                      "row-key": _vm.getRowKey,
                      height: "520px",
                    },
                    on: {
                      "selection-change": _vm.setSelectRows,
                      "cell-click": _vm.cellClick,
                    },
                  },
                  [
                    _vm.isTable
                      ? _c("el-table-column", {
                          attrs: {
                            align: "center",
                            type: "selection",
                            width: "60",
                            "reserve-selection": true,
                          },
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: {
                        prop: "",
                        align: "center",
                        label: "序号",
                        "min-width": "50px",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var $index = ref.$index
                            return [_c("span", [_vm._v(_vm._s($index + 1))])]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "goods_name",
                        align: "center",
                        label: "商品名称",
                        width: "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "specs",
                        align: "center",
                        label: "规格",
                        width: "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "goods_code",
                        align: "center",
                        label: "小单位条码",
                        width: "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "class_name",
                        align: "center",
                        label: "品类",
                        width: "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "brand_name",
                        align: "center",
                        label: "品牌",
                        width: "",
                      },
                    }),
                    _vm.showKeyongkucun
                      ? _c("el-table-column", {
                          attrs: {
                            prop: "depot_stock_num_read",
                            align: "center",
                            label: "可用库存",
                            width: "",
                          },
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: {
                        prop: "unit_cv",
                        align: "center",
                        label: "单位换算",
                        width: "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "is_close_text",
                        align: "center",
                        label: "状态",
                        width: "",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-badge",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isTable,
                  expression: "isTable",
                },
              ],
              staticClass: "item",
              attrs: { value: _vm.tipNum },
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "10px" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }